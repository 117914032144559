// Pulse Partnerships

export default {
  settings: {
    disableIframePostMessage: false,
  },
  content: {
    general: {
      clientName: "Pulse Partnerships Pty Ltd",
    },
    superFund: `
      <div class="section-title mt-5">
        <h2>
          MLC MasterKey Business Super with Pulse Partnerships Pty Ltd
        </h2>
      </div>
      <div class="row content">
        <div class="col-lg-6">
          <p>
            By joining the company Superannuation fund, you benefit from group discount rates on fees and insurances. This means you pay much less than you would pay in a personal or individual plan.
          </p>
          <p>
            This website provides all the information you need around the Pulse Partnerships Pty Ltd Superannuation Fund. There are helpful super tools and calculators, and information on other services we offer if you require additional support with your financial well-being.
          </p>
          <p><b>Fees (per annum)</b></p>
          <ul>
            <li>
              <i class="ri-money-dollar-circle-line"></i> Member Fee:
              <b>$52</b>
            </li>
            <li>
              <i class="ri-money-dollar-circle-line"></i> Administration Fee:
              <b>Nil</b>
            </li>
            <li>
              <i class="ri-money-dollar-circle-line"></i> MySuper Investment Fees & ICR:
              <b>0.84%</b>
            </li>
            <li>
              <i class="ri-money-dollar-circle-line"></i> Transaction costs:
              <b>0.07%</b>
            </li>
            <li>
              <i class="ri-money-dollar-circle-line"></i> Trustee levy:
              <b>0.02%</b>
            </li>
            <li>
              <i class="ri-money-dollar-circle-line"></i> TOTAL:
              <b>$52 + 0.93%</b>
            </li>
          </ul>
          <p>
            <b>Insurances</b>
          </p>
          <p>
            <b>Death & Total Permanent Disability Cover</b>
          </p>
          <ul>
            <li>
              <i class="ri-check-double-line"></i>
              <b>Permanent & Fixed term employees</b>: Level of insurance = 15% x super salary x future years of service
            </li>
            <li>
              <i class="ri-check-double-line"></i>
              <b>Employee Agreement Workers</b>: Age rate scale at a cost of $4.60 p/week
            </li>
            <li>
              <i class="ri-check-double-line"></i>
              <b>Casual & Seasonal employees</b>: Automatically receive 1 unit of Death & TPD cover
            </li>
          </ul>
        </div>
        <div class="col-lg-6 pt-4 pt-lg-0">
          <p>
            <b>Investment Options</b>
          </p>
          <ul>
            <li>
              <i class="ri-check-double-line"></i>
              <strong>Default Investment Option:</strong>
              MLC MySuper
            </li>
            <li>
              <i class="ri-check-double-line"></i>
              <strong>Investment returns available at:</strong>
              <a href="https://www.mlc.com.au/" target="_blank">MLC MasterKey Business Super</a>
            </li>
            <li>
            <i class="ri-check-double-line"></i>
            <p><strong>Vivo Virtual Care</strong>: advice and guidance from leading health experts, anywhere, anytime.
              <a target="_blank" href="https://vivovirtualcare.com.au/">
                Learn more about the Vivo Virtual Care
              </a>
            </p>
          </li>
          </ul>
          <div class="col-lg-6 pt-4 pb-1 px-0 text-center text-lg-center">
            <a
              target="_blank"
              href="https://www.mlc.com.au/personal/investments/prices-and-performance/performance-and-asset-allocation"
              class="btn-learn-more"
            >
              Discover MLC Business & Personal Super Performance
            </a>
          </div>
        </div>
      </div>
    `,
    pdfs: [
      {
        title: "Building your Super Fact Sheet",
        description:
          "View all the different ways you can build your superannuation savings.",
        type: "form",
        url: "Gallagher-Building_Your_Super_Factsheet-2024-2025.pdf",
      },
      {
        title: "Join the Pulse Partnerships fund",
        description:
          "A summary of benefits available to you, including your super choice form.",
        type: "form",
        url: "Pulse_Partnerships-New_Employee_Pack_October_23.pdf",
      },
      {
        title: "ATO Rollover Form",
        description:
          "Do you need to rollover your previous super fund to your new super fund?",
        type: "form",
        externalUrl:
          "https://www.ato.gov.au/Forms/Request-for-rollover-of-whole-balance-of-super-benefits-between-funds---Instructions/#Howtogettheform",
      },
      {
        title: "Product Disclosure Statement",
        description: "In-depth information on your super fund.",
        type: "info",
        externalUrl:
          "https://www.mlc.com.au/adviser/forms-and-documents/pds/superannuation",
      },
      {
        title: "MLC Forms",
        description: "Find the form you need on the MLC website.",
        type: "form",
        externalUrl:
          "https://www.mlc.com.au/personal/forms-and-documents/forms/superannuation",
      },
    ],
    optIn: "MKBS_Choose_ Insurance_form_PMIF(M153909-0421).pdf",
    teamMembers: [
      {
        teamMemberPic: "anthony.png",
        name: "Anthony Warman",
        phoneHref: "0862508308",
        phone: "08 6250 8308",
        email: "anthony_warman@ajg.com.au",
        social: "http://au.linkedin.com/pub/anthony-warman/7b/53a/75a",
      },
      {
        teamMemberPic: "phil.png",
        name: "Phil Mills",
        phoneHref: "0862508399",
        phone: "08 6250 8399",
        email: "phil_mills@ajg.com.au",
        social: "https://au.linkedin.com/pub/philip-mills/67/701/268",
      },
      {
        teamMemberPic: "natasha.png",
        name: "Natasha Van",
        phoneHref: "1300557782",
        phone: "1300 557 782",
        email: "super-service@ajg.com.au",
      },
    ],
    footerClientName: "Pulse Partnerships Pty Ltd",
  },
  superscore: false,

  analytics: {
    gacode: "",
    gaprod: "",
  },
};
